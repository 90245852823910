import React from "react"

const TransactionInProgress = ({ liNumber, className, title, showDesc = false }) => {
  return (
    <div className={`tip ${className}`}>
      <h1>{liNumber}</h1>
      {title
        ? <h2>{title}</h2>
        : <h2>Transaction In Process</h2>
      }
      {showDesc
        ? 
        <p>
          Detecting currency and location of the
          <br />
          customer
        </p>
        :
        <></>
      }
    </div>
  )
}

export default TransactionInProgress
