import { useTransform } from 'framer-motion'
import React from 'react'
import BigGreenCheck from './BigGreenCheck'
import { base_animation_trigger } from './GlobalIntegrationSection'

const BigGreenCheckAnimated = ({scrollYProgress, className}) => {
    const opacity = useTransform(scrollYProgress, [base_animation_trigger+.3, base_animation_trigger+.32], [0, 1])
  const rotate = useTransform(scrollYProgress, [base_animation_trigger+.3, base_animation_trigger+.32], [180, 0])
  const style = {
    rotate,
    opacity,
    transition: {
      ease: "linear",
    },
  }
    return (
        <BigGreenCheck style={style} className={className}/>
    )
}

export default BigGreenCheckAnimated
