import React from "react"
import { motion } from "framer-motion"

function Icon({ className , style}) {
  
  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 173 173"
      className={className}
      style={style}
    >
      <path
        fill="#55B9A3"
        fillRule="evenodd"
        d="M86.5 173c47.773 0 86.5-38.727 86.5-86.5S134.273 0 86.5 0 0 38.727 0 86.5 38.727 173 86.5 173z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#FAFAFA"
        fillRule="evenodd"
        d="M73.419 104.027l4.458-4.993c15.468-17.342 30.939-34.686 46.404-52.032.67-.754 1.433-1.236 2.459-.886 1.279.438 1.654 1.887.801 3.135-1.022 1.495-2.092 2.96-3.141 4.437-16.833 23.679-33.666 47.359-50.507 71.033-.249.349-.538.719-.896.937-1.217.738-2.461.25-3.037-1.149-1.386-3.374-2.726-6.769-4.154-10.126-2.83-6.649-5.864-13.193-10.088-19.108-2.868-4.014-6.21-7.535-10.44-10.16-.513-.317-1.052-.892-1.195-1.447-.402-1.564.704-2.674 2.385-2.325 1.95.403 3.922.861 5.767 1.596 5.036 2.007 8.99 5.507 12.575 9.487 3.007 3.34 5.595 6.982 8.037 10.738.162.251.33.497.572.863z"
        clipRule="evenodd"
      ></path>
    </motion.svg>
  )
}

Icon.defaultProps = {style:{}}

export default Icon
