import React from "react"

function Icon({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <path
        fill="#5fce9c"
        d="M509.121 125.966a9.821 9.821 0 00-13.893 0L256.005 365.194 16.771 125.966a9.821 9.821 0 00-13.893 0 9.821 9.821 0 000 13.893l246.18 246.175a9.819 9.819 0 0013.893 0l246.17-246.175a9.821 9.821 0 000-13.893z"
      ></path>
    </svg>
  )
}

export default React.memo(Icon)
