import styled from "styled-components"

export const StyledLineWrapper = styled.div`
  width: 12px;
  margin-left: -6px;
  display: flex;
  position: absolute;
  left: 50%;
  flex-direction: column;
  align-items: center;
  .line {
    width: 1px;
    height: inherit;
    background: #55B9A3;
    transform-origin: 50% 0%;
  }
  .circle {
    height: 15px;
    width: 15px;
    left: 50%;
    margin-left: -12.5px;
    border-radius: 50%;
    position: absolute;
    border: 5px solid #f4f9fc;
    box-sizing: content-box;
  }
`
