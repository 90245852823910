import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const TopRight = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "global_integration" } }) {
        edges {
          node {
            id
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className="tr-image">
      <Img fluid={data.allFile.edges[0] && data.allFile.edges[0].node.childImageSharp.fluid} />
    </div>
  )
}

export default TopRight
