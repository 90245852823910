import styled from "styled-components"
import { fonts, colors } from "../../../utils/styles"

export const StyledGlobalIntegrationSection = styled.section`
  color: ${colors.primaryBlue};
  background: rgba(143, 197, 234, 0.1);
  
  .chevron {
    color: #5fce9c;
    width: 30px;
  }
  .align-items-center {
    position: relative;
    .house-icon {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: 0px;
    }
  }
  .tip {
    .h1 {
      color: rgba(39, 148, 241, 0.42);
      font-size: 1.3rem;
    }
    h2 {
      font-weight: 600;
      font-size: 1rem;
    }
    p {
      font-size: 0.8rem;
    }
  }
  .top-right {
    margin-top: 50px;
    position: relative;
    .tr-image {
      position: absolute;
      bottom: 100%;
      width: 15%;
      right: 10px;
    }
  }
  .card {
    width: 24rem;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border: none;
    .card-header {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border: none;
      background-color: #f0efef;
      font-weight: 600;
      font-size: 1.25rem;
      img {
        height: 30px;
        width: auto;
      }
    }
    .card-footer {
      border: none;
      background-color: #385da7;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      align-items: baseline;
      img {
        width: 2em !important;
        height: 2em !important;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  .pound {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 12;
    background: #FFFFFF;
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    span {
      font-size: 3rem;
      font-weight: 500;
      width: 100px;
      height: 100px;
      top: 0;
      line-height: 100px;
      background: -webkit-linear-gradient(${colors.secondaryBlue}, #55baa4);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .check-box {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    width: 100px;
    z-index: 12;
    height: 100px;
  }
  .tip {
    transition-timing-function: "ease";
  }
  .tip-2 {
    left: 20%;
    position: relative;
  }

  .last-tip {
    .orch-layer {
      align-items: flex-end;
      svg {
        height: 40px;
      }
      h4 {
        font-family: ${fonts.nunito};
        color: #a2a3a3;
        font-size: 1.1rem;
      }
    }
  }
  /* .row { */
  .curved-line {
    position: absolute;
    bottom: 100%;
    width: 440px;
  }
  .curved-line-1 {
    left: 50%;
  }

  .curved-line-2 {
    transform: scaleX(-1);
    right: 50%;
  }

  .green-check {
    width: 60px;
    box-shadow: 7px 14px 22px rgba(126, 138, 136, 0.5),
      inset 0px 1px 3px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    transform-origin: center !important;
  }
  .divider {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .divider-2 {
    margin-top: -28px;
  }
  .transaction-3 {
    height: 150px;
    margin-bottom: 28px;
  }
`
