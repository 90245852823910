import React from "react"

function Icon({ fill, text }) {
  return (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   fill="none"
    //   viewBox="0 0 231 53"
    // >
    //   <rect
    //     width="231"
    //     height="52.634"
    //     y="0.289"
    //     fill={fill}
    //     fillOpacity="0.6"
    //     rx="26.317"
    //   ></rect>
    // </svg>

    <div
      style={{
        backgroundColor: fill,
        borderRadius: "30px",
        paddingTop: "5px",
        paddingBottom: "5px",
      }}
    >
      <span className= "text-white">
        {text}
      </span>
    </div>
  )
}

Icon.defaultProps = {
  fill: "#55B9A3",
}

export default React.memo(Icon)
