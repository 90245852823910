import styled from "styled-components"
import { motion } from "framer-motion"
import {colors} from "../../../utils/styles"

export const SwitchWrapper = styled(motion.input)`
  position: relative;
  top: 50%;
  margin-top: -16px;
  height: 32px;
  width: 65px;
  border-radius: 16px;
  display: inline-block;

  background: #979797;
  transition: all 0.2s ease;
  &:after {
    content: "";
    position: absolute;
    top: 4px;
    left: 2px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #fefefe;
    box-shadow: 0 1px 2px rgba(44, 44, 44, 0.2);
    transition: all 0.2s cubic-bezier(0.5, 0.1, 0.75, 1.35);
  }
  &:checked {
    background: -webkit-linear-gradient(${colors.secondaryBlue}, #55baa4) !important;

    &::after {
      transform: translate(36px);
    }
  }
  &[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    &:focus {
      outline: 0;
    }
  }
`
